import React, { forwardRef } from "react";

import Tippy, { TippyProps } from "@tippyjs/react";
import cx from "classnames";
import { noop } from "lodash";
import { roundArrow } from "tippy.js";
import "tippy.js/dist/svg-arrow.css";
import "tippy.js/dist/tippy.css";

import { useCurrentFullscreenElement } from "~/components/common/Fullscreen";
import { __isClosingModal } from "~/components/fellow/Modal";

import styles from "./Tooltip.module.css";

/**
 * If we're in the middle of closing a modal, don't show the tooltip.
 * By default, the tooltip would show because the modal returns focus to the thing that triggered it.
 */
function defaultOnShow(): false | void {
	if (__isClosingModal) {
		return false;
	}
	return;
}

/**
 * For dismissible or hover tooltips.
 */
const Tooltip = forwardRef<Element, React.ComponentProps<typeof Tippy>>(function Tooltip(
	{ className, onShow = defaultOnShow, onMount, appendTo, ...rest },
	ref,
) {
	const currentFullscreenElement = useCurrentFullscreenElement();
	const appendToValue = appendTo ?? currentFullscreenElement ?? undefined;
	return (
		<Tippy
			arrow={roundArrow}
			{...rest}
			ref={ref}
			className={cx(className, styles.tippy)}
			onShow={onShow}
			// Fall back to noop because Tippy calls onMount even if it's undefined
			onMount={onMount ?? noop}
			{...(appendToValue ? { appendTo: appendToValue } : {})}
		/>
	);
});

export { useSingleton } from "@tippyjs/react";
export default Tooltip;
export type { TippyProps as TooltipProps };
